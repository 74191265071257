.message {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    font-size: 16px;
}


.message-close {
    cursor: pointer;
    background: transparent;
    border: none;
    font-size: 20px;
    color: #fff;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.message-success {
    background-color: #4caf50;
}

.message-error {
    background-color: #f44336;
}

.message-info {
    background-color: #2196f3;
}

.message-warning {
    background-color: #ff9800;
}



