.codeLang-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.codeLang-item {
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: box-shadow 0.3s ease, background-color 0.3s ease;
    cursor: pointer;
    position: relative;
    max-width: 1000px;
}

.codeLang-item:hover {
    background-color: #e9f3ff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.codeLang-country {
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: bold;
    color: #d9534f;
    flex: 1;
}

.codeLang-content {
    flex: 2;
    padding-left: 20px;
}

.codeLang-item p {
    margin: 5px 0;
    color: #555;
    font-size: 1rem;
}

.codeLang-item p.alert-message {
    color: #d9534f;
    font-weight: bold;
}

.checkbox-container {
    position: absolute;
    top: 10px;
    right: 10px;
}

p.template-uuid-info{
    font-size: 10px;
    color: #c0392b;
}

@media (min-width: 768px) {
    .codeLang-item {
        padding: 30px;
    }
}
