.help-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;}

.help-popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 500px;
    width: 100%;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 34px;
    color: #333;
}

@keyframes rotateQuestionMark {
    0% { transform: rotate(0deg); }
    50% { transform: rotate(20deg); }
    100% { transform: rotate(0deg); }
}

.help-button {
    position: fixed;
    right: 30px;
    bottom: 30px;
    font-size: 34px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    padding: 10px;
    z-index: 1000;
    animation: rotateQuestionMark 2s infinite;
}

/* Styles pour le popup HelpPopUp */
.help-popup-content ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.help-popup-content li {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 10px 15px;
    margin-bottom: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.help-popup-content li:last-child {
    margin-bottom: 0; /* Enlève la marge du dernier élément pour éviter un espacement supplémentaire */
}


