.status {
    padding: 5px 10px;
    color: white;
    border-radius: 15px;
    font-weight: bold;
    text-align: center;
    width: 100px;
    margin: 5px;
}

.status-sent {
    background-color: #5cb85c;
}

.status-completed {
    background-color: #5cb85c;
}

.status-ready {
    background-color: #61dafb;
}

.status-created {
    background-color: #ff9800;
}


.status-active {
    background-color: #7f00ff;
}

.status-error {
    background-color: #d9534f;
}

