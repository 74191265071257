.DashboardEmailing-content .logs {
    background-color: #f2f4f8;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    max-width: 500px;
}

.log-level {
    display: inline-block;
    color: #ffffff;
    border-radius: 4px;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: bold;
}

.log-level-info {
    background-color: #17a2b8;
}

.log-level-error {
    background-color: #dc3545;
}

.ChartContainer {
    margin: 20px auto;
    width: 80%;
    text-align: center;
}

.ChartContainer h3 {
    margin-bottom: 10px;
}

button {
    margin: 20px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}
