.eligible-list {
    list-style: none;
    padding: 0;
}

.eligible-list li {
    background-color: #f5f5f5;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    display: flex;
}


.eligible-item p {
    position: relative;
    padding-right: 30px;
    margin: 5px 0;
}

.eligible-icon img{
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-left: 8px;
}

