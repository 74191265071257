/* EmailPopup.css */
.email-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.email-popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 500px;
    width: 100%;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 34px;
    color: #333;
}

.email-fields-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}

.email-field {
    margin-bottom: 10px;
    width: 100%;
}

.email-label {
    margin-bottom: 5px;
    margin-right: 15px;
    font-weight: bold;
}

.buttons-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
}