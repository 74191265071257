.button {
    padding: 10px 15px;
    border: none;
    color: #fff;
    border-radius: 20px;
    cursor: pointer;
    font-weight: bold;
    text-shadow: 0 -1px 0 rgba(0,0,0,0.2);
    box-shadow: 0 2px 0 rgba(0,0,0,0.045);
    margin: 5px;
    background: #282c34;
}

.button:hover {
    opacity: 0.85;
}

.button-active {
    background-color: #337ab7;
}

.button-paused {
    background-color: #5bc0de;
}

.button-emergency {
    background-color: #d9534f;
    color: #fff;
    border: 1px solid #d43f3a;
    box-shadow: 0 2px 0 rgba(0,0,0,0.1);
}

.button-emergency:hover {
    background-color: #c9302c;
    border-color: #ac2925;
}

