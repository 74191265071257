.text-input {
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 16px;
    max-width: 100%;
}

.prices-input{
    width: 100px;
    padding: 8px;
}