.breadcrumb {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    background: #f5f5f5;
}

.breadcrumb-item {
    padding: 5px 10px;
    background: #495057;
    color: white;
    text-transform: uppercase;
    margin-right: 2px;
    border-radius: 5px;
}

.breadcrumb-item.active {
    background: #d9534f;
}

.breadcrumb-item a {
    color: white;
    text-decoration: none;
}

.breadcrumb-item:not(:last-child)::after {
    content: '>';
    color: white;
    margin-left: 5px;
}



