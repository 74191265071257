.header {
    display: flex;
    align-items: center;
    background-color: #333;
    color: white;
    padding: 10px 20px;
    justify-content: space-between;
}


.header .title {
    font-size: 24px;
    margin-left: 20px;
}
