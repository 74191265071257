.batch-list {
    list-style: none;
    padding: 0;
}

.batch-list li {
    background-color: #f5f5f5;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    display: flex;
    align-items: center;
}

.batch-list li > div{
    margin-right: 30px;
}

.batch-list li p {
    margin: 5px 0;
}
