.custom-checkbox {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.custom-checkbox input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
}

.custom-checkbox .checkbox-indicator {
    width: 20px;
    height: 20px;
    background: white;
    border: 2px solid #555;
    border-radius: 4px;
    margin-right: 10px;
    display: inline-block;
    position: relative;
}

.custom-checkbox input[type="checkbox"]:checked + .checkbox-indicator::after {
    content: "";
    position: absolute;
    left: 6px;
    top: 2px;
    width: 6px;
    height: 10px;
    border: solid #555;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.custom-checkbox .checkbox-label {
    color: #555;
}
